import React, { Component } from "react"
import PropTypes from "prop-types"

import Bouton from "./Bouton"
import {
  elementEstVisible,
  masquerElement,
  objetEstDefini,
  obtenirAffichageElement,
  obtenirElement,
} from "../../js/client-es/utils"

export default class BoutonDerouler extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.classeCSS = props.classeCSS == undefined ? "bouton" : props.classeCSS
    this.element = null
    this.state = { icone: this.obtenirIcone() }
  }

  componentDidMount() {
    this.element = obtenirElement(this.props.idElementAMasquer)
    this.affichageElement = obtenirAffichageElement(this.element)
  }

  render() {
    return this.afficherBoutonDerouler()
  }

  afficherBoutonDerouler() {
    return (
      <Bouton
        texte={this.props.texte}
        fonctionAAppeler={this.masquerElement.bind(this)}
        classeCSS={this.classeCSS}
      >
        {this.state.icone}
      </Bouton>
    )
  }

  masquerElement() {
    masquerElement(this.element, this.props.affichageElementAMasquer)
    /*lorsque l'on met à jour le state, la méthode render est réappelée, 
    ce qui permet de changer l'icône du composant*/
    this.setState({ icone: this.obtenirIcone() })
  }

  obtenirIcone() {
    if (objetEstDefini(this.element) && elementEstVisible(this.element)) {
      return this.iconeFermer()
    } else if (
      objetEstDefini(this.element) &&
      !elementEstVisible(this.element)
    ) {
      return this.iconeOuvrir()
    } else if (this.props.iconeParDefaut === "fermer") {
      return this.iconeFermer()
    } else return this.iconeOuvrir()
  }

  iconeOuvrir() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
      </svg>
    )
  }

  iconeFermer() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </svg>
    )
  }
}

BoutonDerouler.propTypes = {
  texte: PropTypes.string,
  idElementAMasquer: PropTypes.string,
  codePageCible: PropTypes.string,
  classeCSS: PropTypes.string,
  affichageElementAMasquer: PropTypes.string,
  iconeParDefaut: PropTypes.string,
}
