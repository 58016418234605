import React from "react"
import PropTypes from "prop-types"

import Bouton from "./Bouton"

export default function BoutonTelecharger({ texte, lienExterne, classeCSS }) {
  return (
    <Bouton texte={texte} lienExterne={lienExterne} classeCSS={classeCSS}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />
      </svg>
    </Bouton>
  )
}

BoutonTelecharger.propTypes = {
  texte: PropTypes.string,
  lienExterne: PropTypes.string,
  classeCSS: PropTypes.string,
}
