import React from "react"
import PropTypes from "prop-types"

import Lien from "../navigation/Lien"
import { chaineNulleOuVide, objetEstDefini } from "../../js/client-es/utils"

export default function Bouton(props) {
  const lienExterne = props.lienExterne != undefined
  const icone = props.children == undefined ? undefined : props.children
  const classeCSS = props.classeCSS == undefined ? "bouton" : props.classeCSS
  const typeBouton = objetEstDefini(props.type) ? props.type : "button"

  if (lienExterne) {
    return (
      <Lien urlExterne={props.lienExterne} classeCSS="lien-sans-effet">
        <button id={props.id} ref={props.reference} className={classeCSS}>
          <div className="bouton-partie-texte">{props.texte}</div>
          <div className="bouton-partie-icone">{icone}</div>
        </button>
      </Lien>
    )
  } else {
    if (objetEstDefini(props.fonctionAAppeler)) {
      return (
        <a className="lien-sans-effet">
          <button
            id={props.id}
            ref={props.reference}
            onClick={props.fonctionAAppeler}
            type={typeBouton}
            className={classeCSS}
            {...props.attributs}
          >
            <div className="bouton-partie-texte">{props.texte}</div>
            <div className="bouton-partie-icone">{icone}</div>
          </button>
        </a>
      )
    } else if (!chaineNulleOuVide(props.codePageCible)) {
      return (
        <Lien codePageCible={props.codePageCible} classeCSS="lien-sans-effet">
          <button
            id={props.id}
            type={typeBouton}
            ref={props.reference}
            className={classeCSS}
            {...props.attributs}
          >
            <div className="bouton-partie-texte">{props.texte}</div>
            <div className="bouton-partie-icone">{icone}</div>
          </button>
        </Lien>
      )
    } else {
      return (
        <a className="lien-sans-effet">
          <button
            id={props.id}
            ref={props.reference}
            type={typeBouton}
            className={classeCSS}
            {...props.attributs}
          >
            <div className="bouton-partie-texte">{props.texte}</div>
            <div className="bouton-partie-icone">{icone}</div>
          </button>
        </a>
      )
    }
  }
}

Bouton.propTypes = {
  texte: PropTypes.string,
  codePageCible: PropTypes.string,
  children: PropTypes.object,
  fonctionAAppeler: PropTypes.func,
  lienExterne: PropTypes.string,
  attributs: PropTypes.object,
  ref: PropTypes.object,
  enabled: PropTypes.bool,
  type: PropTypes.string,
  reference: PropTypes.object,
  classeCSS: PropTypes.string,
  id: PropTypes.string,
}
