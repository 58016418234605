import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonAcheter from "../../composants/boutons/BoutonAcheter"
import BoutonDerouler from "../../composants/boutons/BoutonDerouler"
import BoutonTelecharger from "../../composants/boutons/BoutonTelecharger"
import Image from "../../composants/image/Image"
import LayoutFR from "../../composants/layout/fr/LayoutFR"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
} from "../../js/client-es/utils"

import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageAstrophotographieReflex: allPagesCompilationYaml(
      filter: { code: { eq: "astrophotographieReflex" } }
    ) {
      ...FragmentPageYaml
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["boutiquePanoramiqueGalactique"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPaysagePetit: allImagesCompilationYaml(
      filter: { code: { in: ["astrophotographieavecUnReflex"] } }
    ) {
      ...FragmentImagePaysagePetit
    }
    imagesPaysageMicro: allImagesCompilationYaml(
      filter: { code: { in: ["moyensPaiement"] } }
    ) {
      ...FragmentImagePaysageMicro
    }
    imagesPortraitPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "astrophotographieavecUnReflexExtrait1"
            "astrophotographieavecUnReflexExtrait3"
          ]
        }
      }
    ) {
      ...FragmentImagePortraitMoyen
    }
  }
`

export default function AstrophotographieReflex() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageAstrophotographieReflex")
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysageMoyen",
    "imagesPaysagePetit",
    "imagesPaysageMicro",
    "imagesPortraitPetit"
  )

  return (
    <LayoutFR page={page}>
      <section className="section-conteneur">
        <p>
          Voie lactée, Lune, comètes, pluies d’étoiles filantes, nébuleuses,
          visites virtuelles… De la prise de vue au traitement sur Photoshop, un
          livre <strong>électronique</strong> de <strong>213 pages</strong> pour
          réussir vos astrophotographies avec votre réflex ou votre hybride !
          Car votre boîtier possède probablement de grandes capacités
          astrophotographiques. Pour vous aider à les exploiter à leur maximum,
          j’ai réuni en un seul ouvrage des conseils et techniques issues de
          plus de 15 années de pratique de l’astrophotographie.
        </p>
        <p>
          Cet ouvrage, résolument orienté « pratique » (Photoshop, expériences
          terrain…) s’adresse aux astrophotographes débutants à semi-confirmés.
        </p>
        <p>
          <strong>43 pages</strong> du livre sont dédiées à la pratique de{" "}
          <strong>l’astrophotographie du ciel profond</strong> (nébuleuses,
          galaxies…) avec un équipement photo « standard » et une petite monture
          équatoriale.
        </p>
        <Image
          image={obtenirImage(images, "astrophotographieavecUnReflex")}
          langue={page.langue}
          afficherLegende={false}
          afficherOmbre={false}
        />
        <div id="telechargement-conteneur" className="conteneur-centre">
          <BoutonTelecharger
            texte="Télécharger un extrait"
            lienExterne="https://drive.google.com/file/d/1AgrtKOrjeXN80QuL9cvc4LEE2gclNLMv/view?usp=sharing"
            classeCSS="bouton bouton-telecharger"
          />
        </div>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(
              images,
              "astrophotographieavecUnReflexExtrait1"
            )}
            langue={page.langue}
            afficherLegende={false}
          />
          <Image
            image={obtenirImage(
              images,
              "astrophotographieavecUnReflexExtrait3"
            )}
            langue={page.langue}
            afficherLegende={false}
          />
        </div>
      </section>
      <Section titre="Un livre vivant et participatif">
        <p>
          Les technologies et les techniques astrophotographiques évoluant
          d’années en années, ce livre sera{" "}
          <strong>mis à jour régulièrement</strong>. Vous recevrez un courriel
          contenant un lien de téléchargement lors de la sortie de chaque
          nouvelle version du livre : son achat vous ouvre le droit à{" "}
          <strong>deux ans</strong> de mises jours gratuites.
        </p>
        <p>
          Ce livre est <strong>participatif</strong> : si vous avez une
          question, si vous y trouvez des informations erronées ou manquantes,
          que vous souhaitez qu’un thème y soit abordé, laissez-moi un message
          sur ma page Facebook ou envoyez-moi un petit email !
        </p>
        <h3>05/08/2022 (V 1.7)</h3>
        <ul>
          <li>Mise à jour des liens.</li>
          <li>Ajout de l’appareil Sony A7c (chap. « Matériel »).</li>
        </ul>
        <h3>11/11/2021 (V. 1.6)</h3>
        Imprimer ses astrophotographies :
        <ul>
          <li>Préparation des images.</li>
          <li>Le mythe des PPP.</li>
          <li>Choix du papier et du support.</li>
        </ul>
      </Section>
      <Section titre="Acheter le livre">
        <p>
          Le livre électronique Astrophotographie avec un réflex est un fichier
          PDF optimisé pour une lecture sur tablette et une impression au format
          A4.
        </p>
        <p>
          Le traitement du paiement est assuré par le site{" "}
          <strong>DPD Cart</strong>. Une fois celui-ci réalisé, vous pourrez
          immédiatement télécharger le livre.
        </p>
        <p>
          L’achat du livre vous donne le droit à{" "}
          <strong>deux ans de mises à jour gratuites</strong>. Vous serez
          informé par courriel de la sortie d’une nouvelle version et vous
          recevrez un lien pour la télécharger.
        </p>
        <p>
          <u>
            <strong>Important</strong>
          </u>{" "}
          : la licence de ce livre électronique est une licence strictement
          personnelle : votre courriel sera inscrit sur la première page du
          fichier PDF que vous téléchargerez. Par respect pour tout le travail
          réalisé, je vous serai reconnaissant de ne pas le diffuser
          illégalement.
        </p>
        <div id="achat-conteneur" className="conteneur-centre">
          <BoutonAcheter
            lien="https://bastienfoucher.dpdcart.com/cart/add?product_id=171144&amp;method_id=184211"
            texte="Acheter maintenant"
            prix="15 €"
          />
        </div>
        <Image
          image={obtenirImage(images, "moyensPaiement")}
          langue={page.langue}
          afficherLegende={false}
        />
        <Image
          image={obtenirImage(images, "boutiquePanoramiqueGalactique")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Mises à jour précédentes">
        <ul>
          <li>
            15/03/2021 (V. 1.5) : ajout des appareils hybrides Canon EOS R5,
            Canon EOS R6, Nikon Z6 II, Sony Alpha 7S III, Fujifilm XT4 (chap. «
            Matériel »). Travailler avec les espaces de couleurs (chap. «
            Photoshop et Lightroom »). La sensibilité ISO : décryptages et
            fausses croyances (mise à jour) (chap. « Les réglages »).
          </li>
          <li>
            07/08/2020 (V. 1.4) : nouvelle couverture, les instruments
            d’astronomie : que choisir ? (chep. « Ciel Profond »), initiation à
            l’autoguidage (chap. « Ciel Profond »).
          </li>
          <li>
            13/01/20 (V. 1.3) : la sensibilité ISO : décryptages et fausses
            croyances (chap. « Les réglages »), augmentation de la luminosité de
            l’image : attention aux étoiles ! (chap. « Ciel profond »), ajout du
            Canon EOS Ra et d’autres boîtiers (chap « Guide d’achat »).
          </li>
          <li>
            03/08/19 (V. 1.2) : les masques de Bahtinov (chap. « Les réglages
            »), diaphragmer par l’avant (chap. « Ciel profond »), piloter son
            appareil depuis un PC (chap. « Ciel profond »).
          </li>
          <li>
            09/06/19 (V. 1.1) : ajout de 2 pages consacrées à la transparence et
            au seeing (chap. « les conditions de prise de vue »).
          </li>
          <li>
            17/02/19 : ajout de nouvelles sections consacrées aux appareils
            hybrides (chap. guide d’achat) et à l’ISO invariance (chap.
            réglages). Ajout de nouveaux liens utiles et de nouveaux plugins
            pour Photoshop.
          </li>
          <li>
            02/12/18 : ajout d’un chapitre de 13 pages consacré à la
            photographie des comètes.
          </li>
        </ul>
      </Section>
      <Section titre="Au sommaire">
        <BoutonDerouler
          texte="Lire la suite…"
          classeCSS="bouton bouton-petit"
          idElementAMasquer="sommaire"
          affichageElementAMasquer="flex"
        />
        <div
          id="sommaire"
          className="conteneur-cote-a-cote-alignement-top"
          style={{ display: "none" }}
        >
          <div className="conteneur-cote-a-cote-texte texte-petit">
            <p>
              <strong>1. Guide d’achat</strong>
            </p>
            <ul>
              <li>Le choix de l’appareil photo</li>
              <li>Quelques suggestions de boîtiers, marque par marque</li>
              <li>Le défiltrage - refiltrage d’un boîtier</li>
              <li>Le choix des objectifs</li>
              <li>Autres accessoires</li>
            </ul>
            <p>
              <strong>2. Les conditions de prise de vue</strong>
            </p>
            <ul>
              <li>Prévoir la météo à l’aide des modèles</li>
              <li>Le vent</li>
              <li>L’humidité</li>
              <li>La pollution lumineuse</li>
              <li>La transparence</li>
              <li>Le seeing</li>
            </ul>
            <p>
              <strong>3. Photoshop et Lightroom</strong>
            </p>
            <ul>
              <li>Quel logiciel utiliser ?</li>
              <li>Une alternative gratuite à Lightroom</li>
              <li>Les masques de fusion</li>
              <li>Traitement du sol et du ciel étoilé.</li>
              <li>Une sélection de plugins pour Photoshop</li>
            </ul>
            <p>
              <strong>4. Les réglages</strong>
            </p>
            <ul>
              <li>Le format RAW</li>
              <li>Le temps de pose</li>
              <li>La sensibilité ISO : décryptages et fausses croyances</li>
              <li>L’ISO invariance</li>
              <li>La mise au point et l’ouverture</li>
              <li>Un premier plan et des étoiles nettes : l’hyperfocale</li>
            </ul>
            <p>
              <strong>5. Réaliser un filé d’étoiles</strong>
            </p>
            <ul>
              <li>Où et quand ?</li>
              <li>Sur le terrain : prise de vue et expériences</li>
              <li>Variez les effets des traînées d’étoiles</li>
              <li>Assemblage de l’image</li>
              <li>Techniques de traitement dans Photoshop</li>
            </ul>
            <p>
              <strong>6. Photographier la Lune</strong>
            </p>
            <ul>
              <li>Où et quand ?</li>
              <li>Un gros plan de la Lune</li>
              <li>La Lune cendrée</li>
              <li>Les éclipses de Lune</li>
              <li>Techniques de traitement dans Photoshop</li>
            </ul>
          </div>
          <div className="conteneur-cote-a-cote-texte texte-petit">
            <p>
              <strong>7. Photographier la Voie lactée</strong>
            </p>
            <ul>
              <li>Le bulbe de la Voie lactée</li>
              <li>Où et quand ?</li>
              <li>Sur le terrain : prise de vue et expériences</li>
              <li>Techniques de traitement dans Photoshop</li>
            </ul>
            <p>
              <strong>8. Photographier l’arche de la Voie lactée</strong>
            </p>
            <ul>
              <li>Où et quand ?</li>
              <li>Equipez-vous d’une rotule panoramique</li>
              <li>Sur le terrain : prise de vue et expériences</li>
              <li>Prétraitement dans Lightroom</li>
              <li>Assemblage de l’image</li>
              <li>Correction des défauts d’assemblage dans Photoshop</li>
              <li>Techniques de traitement dans Photoshop</li>
            </ul>
            <p>
              <strong>9. Réaliser une visite virtuelle</strong>
            </p>
            <ul>
              <li>Sur le terrain : prise de vue et expériences</li>
              <li>Assemblage de l’image</li>
              <li>Techniques de traitement dans Photoshop</li>
              <li>Publication sur Facebook</li>
              <li>Réalisez une « Little Planet »</li>
            </ul>
            <p>
              <strong>10. Photographier les pluies d’étoiles filantes</strong>
            </p>
            <ul>
              <li>Où et quand ?</li>
              <li>Sur le terrain : prise de vue et expériences</li>
              <li>Mise en évidence du radian</li>
              <li>Techniques de traitement dans Photoshop</li>
            </ul>
            <p>
              <strong>11. Photographier les aurores boréales</strong>
            </p>
            <ul>
              <li>Les ovales auroraux</li>
              <li>Où et quand ?</li>
              <li>Quelques belles régions à photographier</li>
              <li>Prévisions des aurores boréales</li>
              <li>Équipement photographique</li>
              <li>Équipement personnel</li>
              <li>Sur le terrain : prise de vue et expériences</li>
              <li>Techniques de traitement dans Lightroom</li>
            </ul>
          </div>
          <div className="conteneur-cote-a-cote-texte texte-petit">
            <p>
              <strong>12. Photographier le ciel profond</strong>
            </p>
            <ul>
              <li>Principes</li>
              <li>Le matériel</li>
              <li>Améliorez votre équipement</li>
              <li>Où et quand ?</li>
              <li>Techniques de mise en station</li>
              <li>Sur le terrain : prise de vue et expériences</li>
              <li>Réaliser de bons darks</li>
              <li>Réaliser de bons flats</li>
              <li>Réalisation d’une mosaïque</li>
              <li>Initiation à l’autoguidage</li>
              <li>Empilement des images</li>
              <li>Techniques de traitement dans Photoshop</li>
              <li>Pour aller plus loin</li>
            </ul>
            <p>
              <strong>13. Photographier les comètes</strong>
            </p>
            <ul>
              <li>Un peu de théorie</li>
              <li>Où et quand ?</li>
              <li>Créer une carte de trajectoire prévisionnelle</li>
              <li>Sur le terrain : prise de vue et expériences</li>
              <li>Empilement des acquisitions</li>
              <li>Techniques de traitement dans Photoshop</li>
            </ul>
            <p>
              <strong>Annexes</strong>
            </p>
            <ul>
              <li>Imprimer ses astrophotographies</li>
              <li>Lectures</li>
              <li>Magasins</li>
              <li>Voyages astronomiques</li>
              <li>Logiciels</li>
              <li>Liens utiles</li>
            </ul>
            <p>
              <strong>A propos de l’auteur</strong>
            </p>
          </div>
        </div>
      </Section>
    </LayoutFR>
  )
}
